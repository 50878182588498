import Vue from 'vue';
import VueRouter from 'vue-router';

import Login from 'tdsAppRoot/components/Auth/Login.vue';
import InstLogin from 'tdsAppRoot/components/Auth/Shibboleth/InstLogin.vue';
import UnPwLoginControls from 'tdsAppRoot/components/Auth/UnPwLoginControls.vue';
import ShibGroupSelect from 'tdsAppRoot/components/Auth/Shibboleth/ShibGroupSelect.vue';
import DirectPendingLogin from 'tdsAppRoot/components/Auth/DirectPendingLogin.vue';
import InstLogoutMsg from 'tdsAppRoot/components/Auth/InstLogoutMsg.vue';
import ErrorPage from 'tdsAppRoot/components/Errors/ErrorPage.vue';
import EulaHost from 'tdsAppRoot/components/Auth/EulaHost.vue';
import ForgotPassword from 'tdsAppRoot/components/Auth/ForgotPassword.vue';
import ResetPassword from 'tdsAppRoot/components/Auth/ResetPassword.vue';
import AlertUnsubscribe from 'tdsAppRoot/components/Alerts/AlertUnsubscribe.vue';
import MFAHandover from 'tdsAppRoot/components/Auth/MFAHandover.vue';
import NotFound from 'tdsAppRoot/components/Errors/NotFound.vue';

import HtmlFilePage from 'tdsAppRoot/components/HtmlFilePage.vue';

import { RouterSetDocumentTitle } from 'tdsAppRoot/library/TDSUtil.js';

import { OverrideRouterPush } from 'tdsAppRoot/router/routerShared.js';
OverrideRouterPush();

Vue.use(VueRouter);


export default function CreateRouter(basePath, store)
{
	const router = new VueRouter({
		mode: 'history',
		routes: [
			{
				path: basePath + 'login', component: Login, meta: { title: appContext.appName },
				children: [
					{
						path: '', component: UnPwLoginControls, name: 'loginRoot', meta: { title: "Login" }, props: (route) => ({
							autologinToken: route.query.al ? route.query.al : null
						})
					},
					{ path: 'InstLogin', component: InstLogin, name: 'instLogin', meta: { title: "Institutional Login" } },
					{ path: 'InstLogoutMsg', component: InstLogoutMsg, name: 'InstLogoutMsg', meta: { title: "Institutional Login" } },
					{ path: 'ForgotPassword', component: ForgotPassword, name: 'forgotPassword', meta: { title: "Forgot Password" } },
					{ path: 'ResetPassword', component: ResetPassword, name: 'passwordReset', props: true, meta: { title: "Password Reset" } }
				]
			},
			{
				path: basePath + 'login/htmlfilepage', component: HtmlFilePage, name: 'htmlfilepagedisplay',
				props: (route) => ({ filetoken: route.query.filetoken })
			},
			{
				path: basePath + "login/EULA", component: EulaHost, name: "eula", props: (route) => ({
					returnUrl: route.query.path ? route.query.path : null
				}),
				meta: {
					title: (themeMetadata.key === "tds_health" ? "STAT!Ref® and TDS Health" : appContext.appName) + " User Responsibilities"
				}
			},
			{
				path: basePath + 'MFAHandover', component: MFAHandover, name: 'mfaHandover',
				props: (route) => ({
					handoverArgs: route.query.a,
					externalGuid: route.query.g,
					isExternalApp: route.query.e === "1",
					callbackMode: route.query.m
				}),
				meta: { title: "Multi-Factor Authentication" }
			},
			{
				path: basePath + "secure", component: Login, alias: basePath + "openathens", meta: { title: "Athens Login" },
				children: [
					{ path: basePath + "secure/ShibEntrypoint", component: ShibGroupSelect, name: 'shibGroupSelect', alias: basePath + "openathens/ShibEntrypoint" }
				]
			},
			{ path: basePath + 'login/PendingLogin', component: DirectPendingLogin, name: 'directPendingLogin_l', meta: { title: "Pending Login" } },
			{ path: basePath + "secure/PendingLogin", component: DirectPendingLogin, name: 'directPendingLogin_s', alias: basePath + "openathens/PendingLogin", meta: { title: "Pending Login" } },
			{
				path: basePath + 'login/error', component: ErrorPage, name: 'error', props: (route) => ({
					errMsg: route.query.errMsg ? route.query.errMsg : route.query.errorText,
					link: route.query.link ? route.query.link : route.query.idplogoutlink,
					supportEmail: route.query.supportEmail,
					supportPhone: route.query.supportPhone,
					linkTxt: route.query.linkTxt,
					pageTitle: route.query.title ? route.query.title : "Error"
				}),
				meta: { title: "Error" }
			},
			{ path: basePath + 'Unsubscribe.aspx', component: AlertUnsubscribe, name: 'alertUnsubscribe', meta: { title: "Unsubscribe" } },
			{ path: basePath + 'ForgotPassword/forgotpassword.aspx', redirect: { name: 'forgotPassword' } },
			// If none of the above matches, use this catch-all:
			{ path: '*', component: NotFound, name: '404' }
		]
	});

	router.afterEach((to, from) =>
	{
		Vue.nextTick(() =>
		{ // This is being done on next tick in order to ensure that the title is applied to the correct history item in the browser's html5 history stack.
			if (typeof document === 'undefined')
				return;
			RouterSetDocumentTitle(to);
		});
	});
	return router;
};