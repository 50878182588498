var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "loginRoot" },
    [
      _c("ModalDialogContainer", {
        attrs: { name: "dialogFade", "transition-name": "dialogFade" },
      }),
      _vm._v(" "),
      _c("ToastContainer"),
      _vm._v(" "),
      _c("MinorErrorIcon"),
      _vm._v(" "),
      _c(
        "div",
        {
          ref: "root",
          staticClass: "contentRoot",
          attrs: { id: "appContentRoot" },
        },
        [
          _vm.errMsg && _vm.errMsg != "null"
            ? _c(
                "div",
                {
                  staticClass: "errorMessage",
                  attrs: { tabindex: "-1", id: "errMsgDiv" },
                },
                [
                  _c("div", { staticClass: "errorMessageTxt" }, [
                    _vm._v(_vm._s(_vm.errMsg)),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "dataWarning" }, [
                    _vm._v(
                      "If you are using a public computer, we recommend you close the browser tab when finished to protect any personal information which may still be in memory."
                    ),
                  ]),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _c("router-view"),
          _vm._v(" "),
          _c("AppFooter", { attrs: { id: "appFooter" } }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }