var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "resetPw" },
    [
      _c("h2", [_vm._v("Forgot Password")]),
      _vm._v(" "),
      _vm.message && !_vm.error
        ? _c("div", [
            _vm._v(
              "\n\t\tYour " +
                _vm._s(_vm.accountTypeName) +
                " password has been set to:\n\t\t"
            ),
            _c("div", { staticClass: "msg" }, [
              _c("div", { staticClass: "pwBox" }, [
                _vm._v(_vm._s(_vm.message)),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "msg" }, [
              _vm._v("A copy of this password has been emailed to you."),
            ]),
          ])
        : _vm.error
        ? _c("div", [_vm._v("\n\t\t" + _vm._s(_vm.message) + "\n\t")])
        : _c("div", [_vm._v("…")]),
      _vm._v(" "),
      _vm.isLoginAccount
        ? [
            _vm.error
              ? _c(
                  "div",
                  { staticClass: "loginLink" },
                  [
                    _c(
                      "router-link",
                      { attrs: { to: { name: "forgotPassword" } } },
                      [_vm._v("Try Again")]
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "loginLink" },
              [
                _c("router-link", { attrs: { to: { name: "loginRoot" } } }, [
                  _vm._v("Return to Login"),
                ]),
              ],
              1
            ),
          ]
        : _vm._e(),
      _vm._v(" "),
      _c("FullScreenLoadingMsg", {
        attrs: { show: _vm.loading, msg: "Processing…" },
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }