import Vue from 'vue';
import App from 'tdsAppRoot/components/Auth/LoginLayout.vue';
import CreateStore from 'tdsAppRoot/store/store.js';
import CreateRouter from 'tdsAppRoot/router/loginIndex.js';
import { CleanCurrentUrl } from 'tdsAppRoot/library/TDSUtil.js';
import vsvg from 'tdsAppRoot/components/Controls/Vsvg.vue';

Vue.component('vsvg', vsvg);

export default function (context) { // context may contain different data when server rendered or client rendered.  At minimum, appPath and contentRootPath are required.
	var VueCookie = require('vue-cookie');
	Vue.use(VueCookie);
	let store = CreateStore();
	store.commit("SetApp", "login");
	let router = CreateRouter(context.appPath, store);
	CleanCurrentUrl(true);
	store.commit("InitAppPath", context);
	let myApp = new Vue({
		router,
		store,
		...App
	});
	if (typeof (window) !== "undefined")
		window.appRoot = myApp;
	return { myApp, router };
};
