var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "instLogoutMsgRoot" }, [
    _vm._v(
      "\n\tYou have been logged out of " +
        _vm._s(_vm.appContext.appName) +
        ", but you have "
    ),
    _c("i", [_vm._v("not")]),
    _vm._v(" been logged out of your Identity Provider."),
    _c("br"),
    _c("br"),
    _vm._v(" "),
    _c("a", { attrs: { href: "https://www.statref.com" } }, [
      _vm._v("Click here to return to www.statref.com"),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }