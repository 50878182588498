var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "loginPanel" },
    [
      _c(
        "div",
        { staticClass: "loginTopBar" },
        [
          _c("LogoImage", {
            staticClass: "logoImg",
            attrs: {
              includeDesc: true,
              width: 280,
              height: 84,
              logoType: "onPrimaryColor",
              rolePresentation: true,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("router-view"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }