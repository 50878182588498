var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "HtmlFilePageContent" }, [
    _c(
      "div",
      { staticClass: "logoTopBar" },
      [
        _vm.notLoggedIn
          ? _c("LogoImage", {
              staticClass: "logoImg",
              attrs: {
                includeDesc: false,
                width: 200,
                logoType: "onPrimaryColor",
              },
            })
          : _vm._e(),
      ],
      1
    ),
    _vm._v(" "),
    !_vm.contentLoaded
      ? _c(
          "div",
          {
            staticClass: "loading",
            attrs: { role: "presentation", "aria-hidden": "true" },
          },
          [
            _c("ScaleLoader", { staticClass: "loadingAnimation" }),
            _vm._v("Loading\n\t"),
          ],
          1
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.contentLoaded ? _c("div", { staticClass: "loadingMsg" }) : _vm._e(),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "root" },
      [
        _vm.iconSprite
          ? _c("vsvg", {
              staticClass: "iconSprite",
              attrs: {
                sprite: _vm.iconSprite,
                title: "",
                desc: "",
                role: "presentation",
                "aria-hidden": "true",
              },
            })
          : _vm._e(),
        _vm._v(" "),
        _c("div", { staticClass: "leftSideText" }, [
          _c("div", { staticClass: "mainTitle" }, [
            _vm._v(_vm._s(_vm.mainTitle)),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "titleBarLink" }, [
            _vm._v(_vm._s(_vm.supportingText)),
          ]),
        ]),
      ],
      1
    ),
    _vm._v(" "),
    _c("div", {
      staticClass: "htmlStr",
      domProps: { innerHTML: _vm._s(_vm.htmlContent) },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }