var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.pla
    ? _c("PendingLogin", {
        attrs: {
          productDetails: _vm.pla.productDetails,
          finishPendingLoginLink: _vm.pla.finishPendingLoginLink,
          isPopup: _vm.pla.isPopup,
          contacts: _vm.pla.contacts,
          perpetualProductsOnly: _vm.pla.perpetualProductsOnly,
        },
      })
    : _c(
        "div",
        [
          _c("br"),
          _vm._v(" The resources you requested are already in use. "),
          _c("router-link", { attrs: { to: { name: "loginRoot" } } }, [
            _vm._v("Return to Login"),
          ]),
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }