var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "pendingLogin" }, [
    _c(
      "div",
      { staticClass: "logo" },
      [
        _c("LogoImage", {
          staticClass: "logoImg",
          attrs: { includeDesc: true, width: 152, logoType: "onPrimaryColor" },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "pendingLoginBody" },
      [
        _c("div", { staticClass: "mainHeader" }, [
          _vm._v("\n\t\t\tTurnaway Notice\n\t\t"),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "turnawayReason" }, [
          _vm._v("\n\t\t\t" + _vm._s(_vm.turnawayReason) + "\n\t\t"),
        ]),
        _vm._v(" "),
        _vm.isDirectPendingLogin
          ? _c(
              "div",
              { staticClass: "loginLink" },
              [
                _c(
                  "router-link",
                  {
                    attrs: { to: { name: "loginRoot" } },
                    nativeOn: {
                      click: function ($event) {
                        return _vm.$emit("Close")
                      },
                    },
                  },
                  [_vm._v("Click here to try a different login.")]
                ),
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.cproductDetails
          ? [
              _c("PendingLoginProductList", {
                staticClass: "section",
                attrs: {
                  finishPendingLoginLink: _vm.finishPendingLoginLink,
                  heading: "Available Titles",
                  productDetails: _vm.cproductDetails,
                  available: true,
                  titles: true,
                },
              }),
              _vm._v(" "),
              _c("PendingLoginProductList", {
                staticClass: "section",
                attrs: {
                  finishPendingLoginLink: _vm.finishPendingLoginLink,
                  heading: "Available Products",
                  productDetails: _vm.cproductDetails,
                  available: true,
                  titles: false,
                },
              }),
              _vm._v(" "),
              _c("PendingLoginProductList", {
                staticClass: "section",
                attrs: {
                  finishPendingLoginLink: _vm.finishPendingLoginLink,
                  heading: "Unavailable Titles",
                  productDetails: _vm.cproductDetails,
                  available: false,
                  titles: true,
                },
              }),
              _vm._v(" "),
              _c("PendingLoginProductList", {
                staticClass: "section",
                attrs: {
                  finishPendingLoginLink: _vm.finishPendingLoginLink,
                  heading: "Unavailable Products",
                  productDetails: _vm.cproductDetails,
                  available: false,
                  titles: false,
                },
              }),
            ]
          : _vm._e(),
        _vm._v(" "),
        typeof _vm.ccontacts !== "undefined" && _vm.ccontacts.length > 0
          ? _c("div", {
              staticClass: "section contacts",
              domProps: { innerHTML: _vm._s(_vm.ccontacts) },
            })
          : _vm._e(),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }