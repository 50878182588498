var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "forgotPw" },
    [
      _c("h2", [_vm._v("Forgot Password")]),
      _vm._v(" "),
      _c("ForgotPasswordBlock", {
        attrs: { accountType: 0, initialUserName: _vm.initialUserName },
        on: { requestSent: _vm.onRequestSent },
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "loginLink" },
        [
          _c("router-link", { attrs: { to: { name: "loginRoot" } } }, [
            _vm._v("Return to Login"),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }